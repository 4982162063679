import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import { Col, Container, Row } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import Content from "../components/App/Content"
import PageBanner from "../components/Common/PageBanner"
import { Helmet } from "react-helmet"
import NetversysHero from "../components/heros/NetversysHero"
import OurBlog from "../components/Index/OurBlog"
import OtherDeepDivesBox from "../components/deepdive/OtherDeepDivesBox"

const DeepDiveSingleItemPage = (
  {
    data: {
      markdownRemark: deepdive,
      blog,
      site: {
        siteMetadata: {
          siteUrl
        }
      }
    }
  }) => {
  return (
    <Layout>
      <Helmet>
        <title>Deep Dive: {deepdive.frontmatter.title}</title>

        <meta name={"description"} content={deepdive.excerpt} />
        <meta name="twitter:card" content="summary_large_image" />
        {deepdive.frontmatter.title &&
          <meta name="twitter:title" content={"LOGENTIS Lexikon. Thema: " +
            deepdive.frontmatter.title} />
        }
        <meta name="twitter:image" content={siteUrl + "/images/og/lexikon-logentis-og.png"} />
        <meta property={"og:image"} content={siteUrl + "/images/og/lexikon-logentis-og.png"} />
        <meta property={"og:description"} content={deepdive.excerpt} />
        <meta property={"og:title"} content={deepdive.frontmatter.title} />

        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [{
            "@type": "Question",
            "name": "${deepdive.frontmatter.title}",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "${deepdive.excerpt.replace(/\n/g, "").replace(/"/g, "'")}"
            }
          }]
        }
      `}</script>
      </Helmet>
      <Navbar />
      <PageBanner
        bannerClassname={"page-title-area"}
        pageTitle="LOGENTIS Deep Dive"
        homePageText="Über uns"
        homePageUrl="/"
        makeH1={false}
        activePageText="Deep Dive"
        breadcrumbs={[["Home", "/"]]}
      />
      <section className="history-area mt-5 pb-70">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div style={{ display: "flex", alignItems: "end", gap: "1em" }}>
                <div className={"d-flex flex-column"} style={{ flexGrow: 1 }}>
                  <div><h1 className={"pt-3"}>{deepdive.frontmatter.title}</h1></div>
                </div>
              </div>

              <Content className="html-text-content"
                       htmlContent={deepdive.html}
              />
            </Col>
            <Col lg={4} md={12}>
              <div className={"ml-3 mb-3"}>
                <Link to={"/deep-dive/"}>
                  <button type="button" className="btn btn-outline-primary"><i className="bx bx-chevron-left" />Zur
                    Übersicht
                  </button>
                </Link>
              </div>
              <OtherDeepDivesBox currentDeepDive={deepdive} />
            </Col>
          </Row>
        </Container>
      </section>

      <NetversysHero />
      <OurBlog lastPosts={blog.edges} />
      <Footer />
    </Layout>
  )
}

export default DeepDiveSingleItemPage

export const pageQuery = graphql`
  query deepdiveById($id: String!) {
    site {
        siteMetadata {
            siteUrl
        }
    }
    markdownRemark(id: { eq: $id }) {
      id
      rawMarkdownBody
      excerpt(pruneLength: 170)
      html
      frontmatter {
        title  
      }
      fields {
        slug
        collection
      }
    }
    blog: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC },
        limit: 3,
        filter: { fields: { collection: { eq: "blog" }}, frontmatter: {disabled: {eq: false}}}
      )
      {
        edges {
            node {
              id
              html
              excerpt(pruneLength: 300)
              fields {
                slug
              }
              frontmatter {
                kurznews
                tags
                preview
                description
                date(formatString: "DD.MM.YYYY")
                title
                moreinfolink
                moreinfotext
                featuredimage {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                authorFull {
                    name
                    authorimage {
                        childImageSharp {
                            gatsbyImageData(width: 40)
                        }
                    }
                }
              }
            }
        }
     }
  }
`
