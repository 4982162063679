import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

const otherDeepDives = [{}]

const OtherDeepDivesBox = ({ currentDeepDive }) => {
  const data = useStaticQuery(
    graphql`
      query deepdivesQuery {
          allPageViews {
            nodes {
              totalCount
              id
            }
          }
          allMarkdownRemark (
            limit: 5
            filter: { fields: { collection: { eq: "deepdive" }}}
          ) {
            nodes {
              id
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }`
  )
  return (
    <div className="services-details-info">

      <div className="services-contact-info">
        <h3 style={{ borderBottom: "2px solid #F67803" }} className={"pb-2"}>Weitere Einträge</h3>

        <ul>
          {data.allMarkdownRemark.nodes.filter(item => item.frontmatter.title !== currentDeepDive.frontmatter.title).map((item) => (
            <li>
              <div className="icon" style={{ top: "0" }}>
                <i className="bx bx-bullseye" />
              </div>
              <span className={"pt-1"} style={{ fontSize: "16px" }}><Link
                to={item.fields.slug}>{item.frontmatter.title}</Link></span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default OtherDeepDivesBox
